import React, { useState, useRef, useEffect, useCallback } from 'react'
import { highLightStyle } from '../../FormDialog'
import Threshold from '../../Threshold'
import InfoIcon from '../InfoIcon'
import InputControlOptions from './InputControlOptions'
import { css } from 'styled-components/macro'
import { HealthDataModelType } from '../../globaltypes'
import RelationalUpdater from '../../Form/RelationalContent/RelationalUpdater'
import { HealthData } from '../../HealthDataTable'
import { AddDialogProps } from './types'

const GroupInputAdd = ({
	addRowData,
	invalidInput,
	data,
	relationalEditState: state,
	relationalDataHandlers,
}: AddDialogProps) => {
	const [highlightedElement, setHighlightedElement] = useState('')
	const codeRef = useRef<HTMLInputElement>(null)
	const {
		createThresholdArray,
		addThreshold,
		updateThreshold,
		removeThreshold,
	} = relationalDataHandlers
	const { thresholds } = state

	const mapThresholdsToState = useCallback(
		(thresholds: HealthData[]) => {
			if (state.thresholds.length) return thresholds

			return createThresholdArray()
		},
		[createThresholdArray, state]
	)

	useEffect(() => {
		mapThresholdsToState([])
		//eslint-disable-next-line
	}, [])

	return (
		<>
			<label
				htmlFor='code'
				onFocus={() => setHighlightedElement('code')}
				css={[highlightedElement === 'code' && highLightStyle]}
			>
				<InfoIcon description='Unique code for observation. If group is chosen code will be generated automatically.' />
				Code: <br />
				<input
					title='code'
					id='code'
					type='text'
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						addRowData('code', event)
					}
					autoFocus
					ref={codeRef}
					required
				/>
			</label>
			{invalidInput && (
				<p
					css={css`
						color: red;
					`}
				>
					Code already exists
				</p>
			)}
			<label
				htmlFor='title'
				onFocus={() => setHighlightedElement('title')}
				css={[highlightedElement === 'title' && highLightStyle]}
			>
				<InfoIcon description='Title of the healthdata entry' />
				Title:
				<input
					title='title'
					id='title'
					type='text'
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						addRowData('title', event)
					}
					required
				/>
			</label>
			<label
				htmlFor='type'
				onFocus={() => setHighlightedElement('type')}
				css={[highlightedElement === 'type' && highLightStyle]}
			>
				<InfoIcon description='Type of data used for measuring' />
				Type:
				<select
					title='type'
					id='type'
					onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
						addRowData('type', event)
					}
					required
				>
					<option value=''></option>
					<option value='SUM of observations'>SUM of observations</option>
					<option value='SUM of depressionscale'>SUM of depressionscale</option>
					<option value='SUM of agstscaleT'>SUM of agstscaleT</option>
					<option value='number'>number</option>
					<option value='points'>points</option>
				</select>
			</label>
			<label
				htmlFor='interval_input'
				onFocus={() => setHighlightedElement('interval')}
				css={[highlightedElement === 'interval' && highLightStyle]}
			>
				<InfoIcon description='Interval between the thresholds as a whole or floating point number' />
				Interval:
				<input
					title='interval_input'
					id='interval_input'
					type='number'
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						addRowData('interval', event)
					}
					step='0.01'
					required
				/>
			</label>
			<label
				htmlFor='thresholds_input'
				onFocus={() => setHighlightedElement('thresholds_input')}
				css={[highlightedElement === 'thresholds_input' && highLightStyle]}
			>
				<InfoIcon description='Thresholds on a given min/max range with outcome. Leave max empty if there is no range.' />
				Thresholds
				<br />
				<Threshold
					addThreshold={addThreshold}
					removeThreshold={removeThreshold}
					updateThreshold={updateThreshold}
					thresholds={thresholds}
				/>
			</label>
			<label
				htmlFor='category_input'
				onFocus={() => setHighlightedElement('category')}
				css={[highlightedElement === 'category' && highLightStyle]}
			>
				<InfoIcon description='Category this healthdata entry is part of' />
				Category:
				<input
					title='category_input'
					id='category_input'
					list='categories'
					type='text'
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						addRowData('category', event)
					}
					required
				/>
				<InputControlOptions
					id='categories'
					providesFor='category'
					data={data}
				/>
			</label>

			<RelationalUpdater
				updateHandlers={relationalDataHandlers}
				state={state}
				context={HealthDataModelType.Group}
			/>
		</>
	)
}

export default GroupInputAdd
