import React, { useCallback, useEffect, useState } from 'react'
import { css } from 'styled-components/macro'
import { highLightStyle } from '../../FormDialog'
import Threshold from '../../Threshold'
import InputControlOptions from './InputControlOptions'
import InfoIcon from '../InfoIcon'
import { HealthDataModelType } from '../../globaltypes'
import RelationalUpdater from '../../Form/RelationalContent/RelationalUpdater'
import { HealthData } from '../../HealthDataTable'
import { EditDialogProps } from './types'

const GroupInputEdit = ({
	editedRow,
	editRowData,
	data,
	relationalEditState: state,
	relationalDataHandlers,
}: EditDialogProps) => {
	const [highlightedElement, setHighlightedElement] = useState<string>('')
	const {
		createThresholdArray,
		addThreshold,
		updateThreshold,
		removeThreshold,
	} = relationalDataHandlers
	const { thresholds } = state

	const mapThresholdsToState = useCallback(
		(thresholds: HealthData[]) => {
			if (state.thresholds.length) return thresholds

			if (thresholds.length) {
				thresholds.forEach((threshold, index) => {
					updateThreshold({ threshold, index })
				})

				return thresholds
			}

			if (!thresholds.length) {
				createThresholdArray()
			}

			return thresholds
		},
		[createThresholdArray, state, updateThreshold]
	)

	const getExistingThresholds = useCallback(() => {
		const existingThresholds = Object.entries(editedRow).find(
			([key]) => key === 'thresholds'
		)?.[1]

		if (typeof existingThresholds !== 'object')
			throw new Error('Error fetch editable thresholds')
		if (!existingThresholds) return []

		return existingThresholds
	}, [editedRow])

	useEffect(() => {
		const existingThresholds = getExistingThresholds()
		mapThresholdsToState(existingThresholds)
		//eslint-disable-next-line
	}, [])

	return (
		<>
			{Object.entries(editedRow)
			.slice(1)
				.map(([key, value], cellIndex) => {
					const uniqueId = `${key}${cellIndex}`
					let inputType = 'text'
					if (typeof value === 'number' && key === 'interval') {
						inputType = 'number'
					}
					return key === 'type' ? (
						<label
							htmlFor='type'
							key={`${uniqueId}_select`}
							onFocus={() => setHighlightedElement(uniqueId)}
							css={css`
								${highlightedElement === uniqueId ? highLightStyle : ''}
							`}
						>
							Type:
							<select
								title='type'
								id='type'
								onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
									editRowData(key, event)
								}
								required
							>
								<option value={value?.toString()}>
									{value?.toString().toUpperCase()}
								</option>
								<option value='SUM of observations'>SUM of observations</option>
								<option value='SUM of depressionscale'>
									SUM of depressionscale
								</option>
								<option value='SUM of agstscaleT'>SUM of agstscaleT</option>
								<option value='number'>number</option>
								<option value='points'>points</option>
							</select>
						</label>
					) : key === 'thresholds' ? (
						<label
							htmlFor='thresholds_input'
							onFocus={() => setHighlightedElement('thresholds_input')}
							css={css`
								${highlightedElement === 'thresholds_input'
									? highLightStyle
									: ''}
							`}
							key={`${uniqueId}_thresholds`}
						>
							<InfoIcon description='Thresholds on a given min/max range with outcome. Leave max empty if there is no range.' />
							Thresholds
							<br />
							{typeof value === 'object' && (
								<Threshold
									addThreshold={addThreshold}
									removeThreshold={removeThreshold}
									updateThreshold={updateThreshold}
									thresholds={thresholds}
								/>
							)}
						</label>
					) : (
						<label
							htmlFor={`${uniqueId}_input`}
							key={`${uniqueId}_label`}
							onFocus={() => setHighlightedElement(uniqueId)}
							css={css`
								${highlightedElement === uniqueId ? highLightStyle : ''}
							`}
						>
							{key.toUpperCase()}:
							<input
								title={uniqueId}
								id={`${uniqueId}_input`}
								key={uniqueId}
								type={inputType}
								{...(key === 'category' && {
									list: 'categories',
								})}
								defaultValue={value?.toString().toUpperCase()}
								{...(typeof value === 'boolean' && {
									defaultChecked: value,
								})}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									editRowData(key, event)
								}
								autoFocus={cellIndex === 0}
								required
								{...(inputType === 'number' && {
									step: '0.01',
								})}
							/>
							{key === 'category' && (
								<InputControlOptions
									id='categories'
									providesFor='category'
									data={data}
								/>
							)}
						</label>
					)
				})}

			<RelationalUpdater
				updateHandlers={relationalDataHandlers}
				state={state}
				context={HealthDataModelType.Group}
			/>
		</>
	)
}

export default GroupInputEdit
