import * as React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { useImmerReducer } from 'use-immer'
import { useFetch } from 'use-http'
import { css } from 'styled-components/macro'

import { useEditorDispatch, useEditorState } from '../../context/EditorProvider'
import { questionReducer } from '../../context/reducers/questionReducer'
import translationsReducer from '../../context/reducers/translationsReducer'

import IdGenerator from '../../utils/IdGenerator'

import Container from '../../components/Container'
import Spinner from '../../components/Spinner'
import VariantSelect from '../../components/VariantSelect'
import BasicInfoFields from './BasicInfoFields'
import ChoicesFields from './ChoicesFields'
import ImageUploadFields from './ImageUploadFields'

import Media from '../../components/Media'
import Button, { ButtonVariant } from '../../components/Button'

import TextInput from '../../components/TextInput'

import { ReactComponent as VideoIcon } from '../../images/video_play.svg'

import {
	SurveyStartType,
	ChoicesQuestionType,
	BasicQuestionType,
	SurveySubmitType,
	isChoicesQuestionType,
	isBasicQuestionType,
	isSurveyStartType,
	QuestionActionType,
	SurveyDefinitionQuestionType,
	SurveyQueryParams,
	TranslationsType,
	TranslationActionType,
	TranslationFieldNamesType,
	TranslationObjectType,
} from '../../types'
import TextArea from '../../components/TextArea'

const Question: React.FC<QuestionProps> = ({ question }) => {
	const { translations } = useEditorState()
	const { request: loggedInRequest, response: loggedInResponse } = useFetch(
		`${process.env.REACT_APP_EXORLIVE_API_URL}/api4/public/auth/LoggedIn/?`,
		{
			credentials: 'include',
		},
		[]
	)

	const [questionState, questionDispatch] = useImmerReducer(
		questionReducer,
		question
	)

	const [translationState, translationDispatch] = useImmerReducer(
		translationsReducer,
		translations
	)
	if (!translationState || !questionState) return null

	const isLoggedIn = loggedInResponse.data > 0
	const currentLanguage = 'en-US'
	const currentLanguageTranslations = translationState[currentLanguage]

	if (loggedInRequest.loading)
		return (
			<QuestionWrapper
				questionState={questionState}
				translationState={translationState}
			>
				<Spinner />
			</QuestionWrapper>
		)

	return (
		<QuestionWrapper
			questionState={questionState}
			translationState={translationState}
		>
			<Container>
				<h3
					css={css`
						margin-right: 2rem;
					`}
				>
					Question
				</h3>
				{isChoicesQuestionType(questionState) ||
				isBasicQuestionType(questionState) ? (
					<VariantSelect
						variantObject={questionState}
						questionDispatch={questionDispatch}
					/>
				) : null}
				{isSurveyStartType(questionState) ? (
					<SurveyStartToggleShow
						questionState={questionState}
						questionDispatch={questionDispatch}
					/>
				) : null}
				<HeaderIngressFields
					questionState={questionState}
					translationDispatch={translationDispatch}
					questionDispatch={questionDispatch}
					currentLanguage={currentLanguage}
					currentLanguageTranslations={currentLanguageTranslations}
				/>
				{isBasicQuestionType(questionState) ? (
					<BasicInfoFields
						questionState={questionState}
						questionDispatch={questionDispatch}
					/>
				) : null}
				{isChoicesQuestionType(questionState) ? (
					<React.Fragment>
						<ImageUploadFields
							questionContext={{
								state: questionState,
								questionDispatch,
							}}
							isLoggedIn={isLoggedIn}
						/>
						<VideoFields
							questionState={questionState}
							questionDispatch={questionDispatch}
						/>
						<ChoicesFields
							isLoggedIn={isLoggedIn}
							choices={questionState.choices}
							questionContext={{
								questionState,
								questionDispatch,
							}}
							translationContext={{
								translationState,
								translationDispatch,
							}}
						/>
					</React.Fragment>
				) : null}
			</Container>
		</QuestionWrapper>
	)
}

const VideoFields: React.FC<VideoFieldsProps> = ({
	questionState,
	questionDispatch,
}) => (
	<div
		css={css`
			margin-top: 2rem;
		`}
	>
		<h4>Video</h4>
		<p>
			Videos are only available through ID, to remove a video set the ID to be
			0.
		</p>
		<div
			css={css`
				display: flex;
				flex-direction: column;
				gap: 1rem;
				@media only screen and (min-width: 766px) {
					flex-direction: row;
				}
			`}
		>
			<div
				css={css`
					flex: 1;
				`}
			>
				<div
					css={css`
						display: flex;
						flex: 1;
						align-self: flex-start;
					`}
					className='media-cell-content'
				>
					<TextInput
						id='video-id'
						label='Video id'
						type='number'
						value={
							typeof questionState.videoId === 'number' &&
							questionState?.videoId > -1
								? questionState.videoId
								: ''
						}
						onChange={(event) => {
							questionDispatch({
								type: 'updateQuestion',
								payload: {
									videoId: parseInt(event.currentTarget.value),
								},
							})
						}}
						containerCss={css`
							margin: 0;
							flex-grow: 1;
						`}
					/>
				</div>
			</div>
			<div
				css={css`
					flex: 1;
				`}
			>
				<p
					css={css`
						font-size: 0.9rem;
						margin: 0;
					`}
				>
					Video preview
				</p>
				{questionState.videoId ? (
					<Media videoId={questionState.videoId} />
				) : (
					<div
						css={css`
							display: flex;
							align-items: center;
							justify-content: center;
							aspect-ratio: 16 / 9;
							width: 100%;
							padding: 1rem 0;
							border: 2px dashed #afb2b7;
							border-radius: 2px;

							> svg {
								height: 40px;
								width: 40px;
								fill: #afb2b7;
							}
						`}
					>
						<VideoIcon />
					</div>
				)}
			</div>
		</div>
	</div>
)

export const QuestionWrapper: React.FC<QuestionWrapperProps> = ({
	questionState,
	translationState,
	children,
}) => {
	const history = useHistory()
	const { search } = useLocation()
	const { orgToRequest, deptToRequest } = useParams<SurveyQueryParams>()
	const surveyDispatch = useEditorDispatch()
	let isDataValid = true

	if (questionState && isBasicQuestionType(questionState)) {
		/* NB: If you make changes to isDataValid remember to also change the warnings in the BasicInfoFields component */
		isDataValid =
			questionState.bmiTagRanges.reduce(
				(
					overlapping: number[],
					bmiTagRangeOne,
					bmiTagRangeOneIndex: number
				) => {
					questionState.bmiTagRanges
						.slice(bmiTagRangeOneIndex + 1)
						.forEach((bmiTagRangeTwo) => {
							if (bmiTagRangeOne === bmiTagRangeTwo) {
								return
							}

							if (
								bmiTagRangeOne.bmiEnd - bmiTagRangeTwo.bmiStart >= 0 &&
								bmiTagRangeTwo.bmiEnd - bmiTagRangeOne.bmiStart >= 0
							) {
								overlapping.push(bmiTagRangeOneIndex)
							}
						})

					return overlapping
				},
				[]
			).length === 0 &&
			!questionState.bmiTagRanges.find((bmiTagRange) => {
				if (isNaN(bmiTagRange.bmiEnd) || isNaN(bmiTagRange.bmiStart))
					return true

				return bmiTagRange.bmiEnd <= bmiTagRange.bmiStart
			})
	}

	const onUpdateClick = () => {
		window.scrollTo(0, 0)

		surveyDispatch({
			type: 'saveQuestion',
			payload: questionState,
		})

		surveyDispatch({
			type: 'saveTranslations',
			payload: translationState,
		})

		history.push({
			pathname: `/editor/${orgToRequest}/${deptToRequest}`,
			search: search,
		})
	}

	const onCancelClick = () => {
		window.scrollTo(0, 0)

		history.push({
			pathname: `/editor/${orgToRequest}/${deptToRequest}`,
			search: search,
		})
	}

	return (
		<>
			<div
				className='cancelUpdateQuestion'
				css={css`
					margin-left: auto;
					margin-right: auto;
					max-width: 1200px;
					padding: 10px 0;
				`}
			>
				<Button type='button' onClick={() => onCancelClick()}>
					Cancel
				</Button>
				{questionState && translationState ? (
					<Button
						type='button'
						variant={ButtonVariant.success}
						disabled={!isDataValid}
						onClick={() => onUpdateClick()}
					>
						Update
					</Button>
				) : null}
			</div>
			{children}
		</>
	)
}

const SurveyStartToggleShow: React.FC<SurveyStartProps> = ({
	questionState,
	questionDispatch,
}) => {
	function onCheckboxToggle() {
		questionDispatch({
			type: 'updateQuestion',
			payload: { hideWhenNotAnswered: !questionState.hideWhenNotAnswered },
		})
	}

	return (
		<div>
			<input
				id='front-page-show-toggle'
				type='checkbox'
				checked={questionState.hideWhenNotAnswered}
				onChange={onCheckboxToggle}
				css={css`
					margin-right: 0.5rem;
				`}
			/>
			<label
				css={css`
					font-size: 0.9rem;
				`}
				htmlFor='front-page-show-toggle'
			>
				Hide front page when the survey is unanswered.
			</label>
		</div>
	)
}

const HeaderIngressFields: React.FC<HeaderIngressFieldsProps> = ({
	questionState,
	translationDispatch,
	questionDispatch,
	currentLanguageTranslations,
	currentLanguage,
}) => {
	const onNickNameChange = (value: string, textKey?: string) => {
		if (textKey) {
			translationDispatch({
				type: 'updateTranslation',
				payload: {
					key: textKey,
					translateTo: currentLanguage,
					text: value,
				},
			})
		} else {
			const newTextKey = `${IdGenerator()}${Date.now()}`

			questionDispatch({
				type: 'updateQuestion',
				payload: {
					nicknameTextKey: newTextKey,
				},
			})

			translationDispatch({
				type: 'addNewTranslation',
				payload: {
					subQuestionHeaderTextKey: newTextKey,
					text: value,
				},
			})
		}
	}

	return (
		<React.Fragment>
			<TextInput
				id={questionState.headerTextKey}
				label='Header'
				placeholder='Question header'
				value={
					currentLanguageTranslations[questionState.headerTextKey]?.text || ''
				}
				onChange={(event) => {
					translationDispatch({
						type: 'updateTranslation',
						payload: {
							key: questionState.headerTextKey,
							translateTo: currentLanguage,
							text: event.currentTarget.value,
						},
					})
				}}
			/>
			<TextInput
				id={questionState?.nicknameTextKey}
				label='Alternate shortened header'
				value={
					currentLanguageTranslations[questionState?.nicknameTextKey]?.text ||
					''
				}
				onChange={(event) =>
					onNickNameChange(
						event.currentTarget.value,
						questionState.nicknameTextKey
					)
				}
			/>
			<TextArea
				id={questionState.ingressTextKey}
				label='Ingress'
				placeholder='Question ingress'
				value={
					currentLanguageTranslations[questionState.ingressTextKey]?.text || ''
				}
				rows={4}
				onChange={(event) => {
					translationDispatch({
						type: 'updateTranslation',
						payload: {
							key: questionState.ingressTextKey,
							translateTo: currentLanguage,
							text: event.currentTarget.value,
						},
					})
				}}
			/>
		</React.Fragment>
	)
}

export default Question

type QuestionProps = {
	question:
		| SurveyStartType
		| BasicQuestionType
		| ChoicesQuestionType
		| SurveySubmitType
}

type VideoFieldsProps = {
	questionState: ChoicesQuestionType
	questionDispatch: React.Dispatch<QuestionActionType>
}

type QuestionWrapperProps = {
	questionState?: SurveyDefinitionQuestionType
	translationState?: TranslationsType
	children: React.ReactNode
}

type SurveyStartProps = {
	questionState: SurveyStartType
	questionDispatch: React.Dispatch<QuestionActionType>
}

type HeaderIngressFieldsProps = {
	questionState: SurveyDefinitionQuestionType
	translationDispatch: React.Dispatch<TranslationActionType>
	questionDispatch: React.Dispatch<QuestionActionType>
	currentLanguageTranslations: TranslationObjectType
	currentLanguage: TranslationFieldNamesType
}
